import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api, createAnalysis as apiCreateAnalysis, updateAnalysis as apiUpdateAnalysis, fetchAnalysisData as apiFetchAnalysisData } from '../services/api';

export const fetchAnalysisHistory = createAsyncThunk(
  'analysis/fetchHistory',
  async (params) => {
    const response = await api.get('/api/analysis/history', { params });
    return response.data;
  }
);

export const createAnalysis = createAsyncThunk(
  'analysis/createAnalysis',
  async (data) => {
    const response = await apiCreateAnalysis(data);
    return response;
  }
);

export const updateAnalysis = createAsyncThunk(
  'analysis/updateAnalysis',
  async ({ analisis_id, data }) => {
    const response = await apiUpdateAnalysis(analisis_id, data);
    return response;
  }
);

export const fetchAnalysisData = createAsyncThunk(
  'analysis/fetchAnalysisData',
  async (analisis_id) => {
    const response = await apiFetchAnalysisData(analisis_id);
    return response;
  }
);


const analysisSlice = createSlice({
  name: 'analysis',
  initialState: {
    history: [],
    currentAnalysis: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setCurrentAnalysis(state, action) {
      state.currentAnalysis = action.payload;
    },
    // Otros reducers
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalysisHistory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAnalysisHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.history = action.payload.data;
      })
      .addCase(fetchAnalysisHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createAnalysis.fulfilled, (state, action) => {
        state.currentAnalysis = action.payload;
      })
      .addCase(updateAnalysis.fulfilled, (state, action) => {
        state.currentAnalysis = action.payload;
      })
      .addCase(fetchAnalysisData.fulfilled, (state, action) => {
        state.currentAnalysis = action.payload;
      });
    // Maneja otros thunks
  },
});

export const { setCurrentAnalysis } = analysisSlice.actions;

export default analysisSlice.reducer;
