// src/services/openai.js
import axios from 'axios';

// Definir las constantes para la API Key y el Assistant ID
const OPENAI_API_KEY = 'sk-proj-ViubkhV07TAc9aOkwrCET3BlbkFJk3DACaoxW7BfgyCr02Xm'; // API Key de OPENAI
const OPENAI_ASSISTANT_ID = 'asst_SwEORLsOf0BGStLuuyYWTxLs'; // assistant_id

// Configurar Axios para OpenAI API
const openaiApi = axios.create({
  baseURL: 'https://api.openai.com/v1',
  headers: {
    Authorization: `Bearer ${OPENAI_API_KEY}`,
    'OpenAI-Beta': 'assistants=v2',
  },
});

// Función para subir un archivo a OpenAI y obtener el file_id
export const uploadFile = async (file, isImage = false) => {
  const formData = new FormData();
  formData.append('file', file);

  // Usar el propósito adecuado: 'vision' para imágenes, 'assistants' para otros archivos
  const purpose = isImage ? 'vision' : 'assistants';
  formData.append('purpose', purpose);

  const response = await openaiApi.post('/files', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data.id; // Devolver el file_id
};


// Función para eliminar un archivo de OpenAI
export const deleteFile = async (fileId) => {
  const response = await openaiApi.delete(`/files/${fileId}`);
  return response.data;
};

// Función para crear un thread
export const createThread = async (messages) => {
  const response = await openaiApi.post('/threads', { messages });
  return response.data;
};

// Función para crear un run
export const createRun = async (threadId) => {
  const response = await openaiApi.post(`/threads/${threadId}/runs`, {
    assistant_id: OPENAI_ASSISTANT_ID,
  });
  return response.data;
};

// Función para obtener el estado de un run
export const getRunStatus = async (threadId, runId) => {
  const response = await openaiApi.get(`/threads/${threadId}/runs/${runId}`);
  return response.data;
};

// Función para listar mensajes en un thread
export const listMessages = async (threadId) => {
  const response = await openaiApi.get(`/threads/${threadId}/messages`);
  return response.data.data; // La lista de mensajes está en la propiedad 'data'
};

// Función para enviar un mensaje a un thread existente
export const createMessage = async (threadId, message) => {
  const response = await openaiApi.post(`/threads/${threadId}/messages`, {
    role: message.role,
    content: message.content,
    attachments: message.attachments || [],
  });
  return response.data;
};

// Función para esperar a que un run se complete
export const waitForRunCompletion = async (threadId, runId) => {
  let runStatus = 'pending';
  while (runStatus !== 'completed' && runStatus !== 'failed') {
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Espera 2 segundos
    const runData = await getRunStatus(threadId, runId);
    runStatus = runData.status;
    console.log(`Estado actualizado del run: ${runStatus}`);
  }
  if (runStatus === 'failed') {
    throw new Error('El run ha fallado.');
  }
};

export const extractDataFromFile = async (file) => {
  let fileId;
  try {
    // Determinar si el archivo es una imagen
    const fileType = file.type;
    const isImage = fileType.startsWith('image/');
    console.log('Tipo de archivo:', fileType, '¿Es imagen?', isImage);

    // Subir el archivo a OpenAI y obtener el file_id
    fileId = await uploadFile(file, isImage);
    console.log(`Archivo subido con file_id: ${fileId}`);

    // Construir el mensaje con el archivo adjunto
    let messages;

    if (isImage) {
      // Para imágenes, usar el tipo "image_file"
      messages = [
        {
          role: 'user',
          content: [
            {
              type: 'text',
              text: 'Por favor, analiza esta imagen y extrae la información relevante. Devuelve únicamente la respuesta en formato JSON válido, sin ninguna explicación adicional, y **no incluyas delimitadores de código** como ```json o ```. Las claves que debes incluir son: paciente_dni, paciente_nombre, paciente_apepat (apellido paterno), paciente_apemat (apellido materno), paciente_edad (solo el número), paciente_sexo, muestra_tipo, muestra_lugar (la fuente u origen de donde se extrajo la muestra), muestra_fecha (formato yyyy-MM-dd), cultivo_conteoufc, cultivo_metodo, microorganismo_nombre, antibioticos_sensibilidad (lista de objetos con nombre, cmi y sensibilidad).',
            },
            {
              type: 'image_file',
              image_file: {
                file_id: fileId,
                detail: 'auto', // Opcional: Puede ser 'low', 'high' o 'auto'
              },
            },
          ],
        },
      ];
    } else {
      // Para otros archivos (PDF u otros), usar "attachments"
      messages = [
        {
          role: 'user',
          content: 'Por favor, analiza este archivo y extrae la información relevante. Devuelve únicamente la respuesta en formato JSON válido, sin ninguna explicación adicional, y **no incluyas delimitadores de código** como ```json o ```. Las claves que debes incluir son: paciente_dni, paciente_nombre (nombres del paciente), paciente_apepat, paciente_apemat, paciente_edad (solo el número), paciente_sexo, muestra_tipo, muestra_lugar, muestra_fecha (formato yyyy-MM-dd), cultivo_conteoufc, cultivo_metodo, microorganismo_nombre, antibioticos_sensibilidad (lista de objetos con nombre, cmi y sensibilidad).',
          attachments: [{ file_id: fileId, tools: [{ type: 'file_search' }] }],
        },
      ];
    }

    // Crear un thread con el mensaje
    const threadResponse = await createThread(messages);
    const threadId = threadResponse.id;

    // Crear run
    const runResponse = await createRun(threadId);
    const runId = runResponse.id;

    // Esperar a que el run se complete
    await waitForRunCompletion(threadId, runId);

    // Obtener mensajes del thread
    const messagesResponse = await listMessages(threadId);

    const assistantMessage = messagesResponse.find(
      (msg) => msg.role === 'assistant'
    );

    if (!assistantMessage) {
      throw new Error(
        'No se encontró un mensaje del asistente después de la finalización del run.'
      );
    }

    console.log(assistantMessage.content)
    // Obtener el contenido del mensaje del asistente
    const assistantContent = assistantMessage.content
      .map((contentPiece) => {
        if (contentPiece.type === 'text') {
          return contentPiece.text.value;
        }
        return '';
      })
      .join(' ');

    // Parsear el JSON
    let extractedData;
    try {
      extractedData = JSON.parse(assistantContent);
    } catch (parseError) {
      console.error('Error al parsear el contenido del asistente:', parseError);
      console.error('Contenido del asistente:', assistantContent);
      throw new Error(
        'No se pudo parsear la respuesta del asistente como JSON. Asegúrate de que el asistente esté devolviendo un JSON válido.'
      );
    }

    return extractedData;
  } catch (error) {
    console.error('Error en extractDataFromFile:', error);
    if (error.response) {
      console.error('Error status:', error.response.status);
      console.error('Error data:', error.response.data);
    }
    throw error;
  } finally {
    // Eliminar el archivo subido para liberar espacio
    if (fileId) {
      try {
        const deleteResponse = await deleteFile(fileId);
        console.log(`Archivo con file_id: ${fileId} eliminado correctamente.`);
      } catch (deleteError) {
        console.error(
          `Error al intentar eliminar el archivo con file_id: ${fileId}`,
          deleteError
        );
      }
    }
  }
};

export default openaiApi;