import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://antibiograma-api.fly.dev',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getThreadIdByAnalysisId = async (analysisId) => {
  const response = await api.get(`/api/chat/${analysisId}/threadId`);
  return response.data.thread_id;
};

// Funciones para análisis
export const createAnalysis = async (data) => {
  const response = await api.post('/api/analysis/new', data);
  return response.data;
};

export const updateAnalysis = async (analisis_id, data) => {
  const response = await api.put(`/api/analysis/edit/${analisis_id}`, data);
  return response.data;
};

export const fetchAnalysisData = async (analisis_id) => {
  const response = await api.get(`/api/summary/${analisis_id}`);
  return response.data;
};

// Función para guardar threadId
export const saveThreadId = async (data) => {
  const response = await api.post('/api/chat/saveThreadId', data);
  return response.data;
};

export default api;
