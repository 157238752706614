import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function HistoryTable({ data }) {
  const navigate = useNavigate();

  const handleItemClick = (analysisId) => {
    navigate(`/chat/${analysisId}`);
  };

  return (
    <List>
      {data.map((item) => (
        <div key={item.analisis_id}>
          <ListItem button onClick={() => handleItemClick(item.analisis_id)}>
            <ListItemAvatar>
              <Avatar>
                {/* Puedes usar un icono aquí */}
                <i className="material-icons">assignment</i>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.nombre_paciente}
              secondary={`${item.nombre_microorganismo} - ${item.tipo_muestra} - ${item.fecha_registro}`}
            />
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
}

export default HistoryTable;
