import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, TextField } from '@mui/material';

function DateRangePicker({ startDate, endDate, onChange }) {
  const [dates, setDates] = useState([startDate, endDate]);
  const [start, end] = dates;

  const handleChange = (update) => {
    setDates(update);
    onChange(update);
  };

  return (
    <Box>
      <DatePicker
        selectsRange
        startDate={start}
        endDate={end}
        onChange={handleChange}
        isClearable={true}
        customInput={<TextField label="Select Date Range" variant="outlined" />}
      />
    </Box>
  );
}

export default DateRangePicker;
