import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  Container,
  Pagination,
} from '@mui/material';
import {
  Search,
  FilterList,
  Clear,
  AddCircleOutline,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAnalysisHistory } from '../../state/analysisSlice';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import HistoryTable from '../HistoryTable/HistoryTable';
import DateRangePicker from '../DateRangePicker/DateRangePicker';

function HomePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const analysisHistory = useSelector((state) => state.analysis.history);
  const totalPages = useSelector((state) => state.analysis.totalPages);
  const [searchText, setSearchText] = useState('');
  const [dates, setDates] = useState([null, null]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      fetchAnalysisHistory({
        searchText,
        startDate: dates[0],
        endDate: dates[1],
        page,
      })
    );
  }, [dispatch, searchText, dates, page]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearFilters = () => {
    setSearchText('');
    setDates([null, null]);
  };

  const handleNewAnalysis = () => {
    navigate('/new-analysis');
  };

  return (
    <>
      <Header title={`Bienvenido, (nombre de usuario)`} />
      <Box sx={{ padding: 2 }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <TextField
            variant="outlined"
            placeholder="Buscar en el historial"
            value={searchText}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <DateRangePicker
            startDate={dates[0]}
            endDate={dates[1]}
            onChange={(update) => setDates(update)}
          />
          {(searchText || dates[0] || dates[1]) && (
            <IconButton onClick={handleClearFilters}>
              <Clear />
            </IconButton>
          )}
        </Box>

        {/* Historial */}
        <HistoryTable data={analysisHistory} />

        {/* Paginación */}
        <Pagination
          count={totalPages}
          page={page}
          onChange={(e, value) => setPage(value)}
          sx={{ marginTop: 2 }}
        />

        {/* Botón de Nuevo Análisis */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          onClick={handleNewAnalysis}
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Nuevo Análisis
        </Button>
      </Box>
    </>
  );
}

export default HomePage;
