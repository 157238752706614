import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/api';

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const response = await api.get('/api/users');
  return response.data;
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: {},
    status: 'idle',
    error: null,
  },
  reducers: {
    // Puedes agregar acciones sincronas aquí
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        console.log('Usuario cargado:', action.payload); // Depuración
      })      
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
