import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import analysisReducer from './analysisSlice';
import chatReducer from './chatSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    analysis: analysisReducer,
    chat: chatReducer,
  },
});

export default store;