// src/state/chatSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getThreadIdByAnalysisId, saveThreadId as saveThread } from '../services/api';
import {
  createMessage,
  listMessages,
  createRun,
  waitForRunCompletion,
} from '../services/openai';

export const fetchThreadId = createAsyncThunk(
  'chat/fetchThreadId',
  async (analysisId, { rejectWithValue }) => {
    try {
      const threadId = await getThreadIdByAnalysisId(analysisId);
      return threadId;
    } catch (error) {
      console.error('Error en fetchThreadId:', error);
      return rejectWithValue(error.response?.data || 'Error al obtener threadId');
    }
  }
);

export const fetchMessages = createAsyncThunk(
  'chat/fetchMessages',
  async (threadId) => {
    const messages = await listMessages(threadId);
    return messages;
  }
);

export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async ({ threadId, messageContent }, { dispatch }) => {
    const message = {
      role: 'user',
      content: messageContent,
    };
    await createMessage(threadId, message);

    // Crear un run para que el asistente procese el mensaje
    const assistantId = 'YOUR_ASSISTANT_ID'; // Reemplaza con tu assistant_id
    const runData = await createRun(threadId, assistantId);
    const runId = runData.id;

    // Esperar a que el run se complete
    await waitForRunCompletion(threadId, runId);

    // Después de enviar el mensaje, obtenemos nuevamente la lista de mensajes
    await dispatch(fetchMessages(threadId));
  }
);

export const saveThreadId = createAsyncThunk(
  'chat/saveThreadId',
  async ({ analisis_id, thread_id, usuario_id }) => {
    const response = await saveThread({ analisis_id, thread_id, usuario_id });
    return response;
  }
);

export const sendUpdatedDataMessage = createAsyncThunk(
  'chat/sendUpdatedDataMessage',
  async ({ analysisId, formData }, { getState, dispatch }) => {
    const threadId = getState().chat.threadId;

    // Formatear los datos como texto estructurado
    const content = `
    Generar informe corto con la siguiente estructura: Nombre microorganismo, patrones de fenotipos encontrados de resistencia y opciones terapéuticas según fenotipo de resistencia del microorganismo en orden, empezando por la mejor opción, según los siguientes datos:
    
    **Datos del paciente:**
    - Nombre: ${formData.paciente_nombre}
    - Apellido paterno: ${formData.paciente_apepat}
    - Apellido materno: ${formData.paciente_apemat}
    - Edad: ${formData.paciente_edad}
    - Sexo: ${formData.paciente_sexo}
    - DNI: ${formData.paciente_dni}

    **Detalles de la muestra:**
    - Tipo de Muestra: ${formData.muestra_tipo}
    - Lugar de Toma de Muestra: ${formData.muestra_lugar}
    - Fecha de Toma de Muestra: ${formData.muestra_fecha}

    **Detalles del cultivo:**
    - Conteo UFC: ${formData.cultivo_conteoufc}
    - Método de cultivo: ${formData.cultivo_metodo}
    
    **Microorganismo identificado:**
    ${formData.microorganismo_nombre}

    **Resultados de Sensibilidad de Antibióticos:**
    ${formData.antibioticos_sensibilidad
      .map(
        (antibiotico, index) =>
          `    ${index + 1}. ${antibiotico.nombre} - CMI: ${antibiotico.cmi} - Sensibilidad: ${antibiotico.sensibilidad}`
      )
      .join('\n')}`.trim();

    // Enviar el mensaje
    await createMessage(threadId, { role: 'user', content });

    // Crear un run para que el asistente procese el mensaje
    const assistantId = 'YOUR_ASSISTANT_ID'; // Reemplaza con tu assistant_id
    const runData = await createRun(threadId, assistantId);
    const runId = runData.id;

    // Esperar a que el run se complete
    await waitForRunCompletion(threadId, runId);

    // Obtener los mensajes actualizados
    await dispatch(fetchMessages(threadId));
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    threadId: null,
    messages: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // Puedes agregar acciones síncronas aquí si es necesario
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchThreadId.fulfilled, (state, action) => {
        state.threadId = action.payload;
      })
      .addCase(fetchMessages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.messages = action.payload;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(sendMessage.pending, (state) => {
        state.sendingStatus = 'loading';
      })
      .addCase(sendMessage.fulfilled, (state) => {
        state.sendingStatus = 'succeeded';
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.sendingStatus = 'failed';
        state.error = action.error.message;
      })
      .addCase(saveThreadId.fulfilled, (state, action) => {
        state.threadId = action.payload.thread_id;
      })
      .addCase(sendUpdatedDataMessage.pending, (state) => {
        state.sendingStatus = 'loading';
      })
      .addCase(sendUpdatedDataMessage.fulfilled, (state) => {
        state.sendingStatus = 'succeeded';
      })
      .addCase(sendUpdatedDataMessage.rejected, (state) => {
        state.sendingStatus = 'failed';
      });
  },
});

export default chatSlice.reducer;
