import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './themes/theme';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchUser } from './state/userSlice';
import HomePage from './components/HomePage/HomePage';
import NewAnalysisPage from './components/NewAnalysisPage/NewAnalysisPage';
import SummaryPage from './components/SummaryPage/SummaryPage';
import ChatPage from './components/ChatPage/ChatPage';
import { useSelector } from 'react-redux';

function App() {
  const user = useSelector((state) => state.user.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    console.log('Datos del usuario cargados en App:', user);
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/new-analysis" element={<NewAnalysisPage />} />
          <Route path="/summary" element={<SummaryPage />} />
          <Route path="/summary/:analysisId" element={<SummaryPage />} />
          <Route path="/chat/:analysisId" element={<ChatPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
