import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import Header from '../Header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAnalysis,
  updateAnalysis,
  fetchAnalysisData,
} from '../../state/analysisSlice';
import { saveThreadId, sendUpdatedDataMessage } from '../../state/chatSlice';
import { createThread } from '../../services/openai';

function SummaryPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { analysisId } = useParams();
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state) => state.user.data.usuario_id);

  useEffect(() => {
    if (analysisId) {
      dispatch(fetchAnalysisData(analysisId)).then((response) => {
        setFormData(response.payload);
      });
    } else {
      const extractedData = JSON.parse(localStorage.getItem('extractedData'));
      setFormData(extractedData);
    }
  }, [analysisId, dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContinue = async () => {
    setLoading(true);
    try {
      let analisisId = analysisId;
      const transformedFormData = {
        ...formData,
        muestra_fecha: new Date(formData.muestra_fecha).toISOString().split('T')[0],
      };

      if (analysisId) {
        await dispatch(updateAnalysis({ analisis_id: analysisId, data: transformedFormData }));
        await dispatch(sendUpdatedDataMessage({ analysisId, formData: transformedFormData }));
      } else {
        const response = await dispatch(createAnalysis(transformedFormData));
        if (!response.payload || !response.payload.analisis_id) {
          throw new Error('El backend no devolvió un analisis_id válido.');
        }

        analisisId = response.payload.analisis_id;
        const threadResponse = await createThread();
        const threadId = threadResponse.id;

        await dispatch(
          saveThreadId({
            analisis_id: analisisId,
            thread_id: threadId,
            usuario_id: 1,
          })
        );

        await dispatch(sendUpdatedDataMessage({ analysisId: analisisId, formData: transformedFormData }));
      }

      navigate(`/chat/${analisisId}`);
    } catch (error) {
      console.error('Error al procesar el análisis:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (!analysisId) {
      localStorage.removeItem('extractedData');
    }
    navigate(analysisId ? `/chat/${analysisId}` : '/new-analysis');
  };

  if (!formData) {
    return <CircularProgress />;
  }

  return (
    <>
      <Header
        title="Resumen de análisis. Revise y confirme los datos extraídos."
        showBackButton
        onBack={handleBack}
      />
      <Box sx={{ padding: 2 }}>
        {/* Información del Paciente */}
        <Typography variant="h6" gutterBottom>
          Información del paciente
        </Typography>
        <TextField
          label="Nombre"
          name="paciente_nombre"
          value={formData.paciente_nombre}
          onChange={handleInputChange}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Apellido Paterno"
          name="paciente_apepat"
          value={formData.paciente_apepat}
          onChange={handleInputChange}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Apellido Materno"
          name="paciente_apemat"
          value={formData.paciente_apemat}
          onChange={handleInputChange}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Edad"
              name="paciente_edad"
              type="number"
              value={formData.paciente_edad}
              onChange={handleInputChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Sexo"
              name="paciente_sexo"
              value={formData.paciente_sexo}
              onChange={handleInputChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="DNI"
              name="paciente_dni"
              value={formData.paciente_dni}
              onChange={handleInputChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          </Grid>
        </Grid>

        {/* Detalles de la Muestra */}
        <Typography variant="h6" gutterBottom>
          Detalles de la muestra
        </Typography>
        <TextField
          label="Tipo"
          name="muestra_tipo"
          value={formData.muestra_tipo}
          onChange={handleInputChange}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Lugar"
          name="muestra_lugar"
          value={formData.muestra_lugar}
          onChange={handleInputChange}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Fecha"
          name="muestra_fecha"
          type="date"
          value={formData.muestra_fecha}
          onChange={handleInputChange}
          fullWidth
          sx={{ marginBottom: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        {/* Detalles del Cultivo */}
        <Typography variant="h6" gutterBottom>
          Detalles del cultivo
        </Typography>
        <TextField
          label="Conteo UFC"
          name="cultivo_conteoufc"
          value={formData.cultivo_conteoufc}
          onChange={handleInputChange}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Método"
          name="cultivo_metodo"
          value={formData.cultivo_metodo}
          onChange={handleInputChange}
          fullWidth
          sx={{ marginBottom: 2 }}
        />

        {/* Microorganismo Identificado */}
        <Typography variant="h6" gutterBottom>
          Microorganismo identificado
        </Typography>
        <TextField
          label="Nombre"
          name="microorganismo_nombre"
          value={formData.microorganismo_nombre}
          onChange={handleInputChange}
          fullWidth
          sx={{ marginBottom: 2 }}
        />

        {/* Resultados de Sensibilidad */}
        <Typography variant="h6" gutterBottom>
          Resultados de sensibilidad
        </Typography>
        {formData.antibioticos_sensibilidad.map((antibiotico, index) => (
          <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }}>
            <Grid item xs={4}>
              <TextField
                label="Nombre"
                name={`antibioticos_sensibilidad[${index}].nombre`}
                value={antibiotico.nombre}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="CMI"
                name={`antibioticos_sensibilidad[${index}].cmi`}
                value={antibiotico.cmi}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Sensibilidad"
                name={`antibioticos_sensibilidad[${index}].sensibilidad`}
                value={antibiotico.sensibilidad}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        ))}

        {/* Botón Continuar */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 4 }}
          onClick={handleContinue}
          disabled={loading}
        >
          {loading ? 'Guardando...' : 'Continuar'}
        </Button>
      </Box>
    </>
  );
}

export default SummaryPage;
