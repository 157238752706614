// src/components/ChatPage/ChatPage.js
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Paper,
} from '@mui/material';
import { ArrowBack, Edit } from '@mui/icons-material';
import Header from '../Header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchThreadId, fetchMessages, sendMessage } from '../../state/chatSlice';

function ChatPage() {
  const { analysisId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);

  const threadId = useSelector((state) => state.chat.threadId);
  const messages = useSelector((state) => state.chat.messages);
  const chatStatus = useSelector((state) => state.chat.status);
  const chatError = useSelector((state) => state.chat.error);
  const sendingStatus = useSelector((state) => state.chat.sendingStatus);

  const [inputText, setInputText] = useState('');

  useEffect(() => {
    dispatch(fetchThreadId(analysisId));
  }, [dispatch, analysisId]);

  useEffect(() => {
    if (threadId) {
      dispatch(fetchMessages(threadId));
    }
  }, [dispatch, threadId]);

  // Desplazamiento automático al último mensaje
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSend = () => {
    if (inputText.trim() === '') return;
    dispatch(sendMessage({ threadId, messageContent: inputText }));
    setInputText('');
  };

  const handleViewEditResults = () => {
    navigate(`/summary/${analysisId}`);
  };

  const handleBack = () => {
    navigate('/');
  };

  // Función para formatear el contenido del mensaje
  const formatMessageContent = (content) => {
    // Reemplazar saltos de línea
    const formattedText = content.replace(/\n/g, '<br />');

    // Detectar y formatear texto en negrita (**palabra**)
    const formattedWithBold = formattedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Dividir en párrafos usando etiquetas <span> para mantener el formato
    return formattedWithBold.split('<br />').map((line, index) => (
      <Typography
        key={index}
        component="span"
        sx={{ display: 'block', whiteSpace: 'pre-line', lineHeight: 1.6 }} // Aumentar el espacio entre líneas
        dangerouslySetInnerHTML={{ __html: line }}
      />
    ));
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      {/* Barra Superior Fija */}
      <Header
        title={`Paciente: Nombre del paciente. Resultados del análisis.`}
        showBackButton
        onBack={handleBack}
      />

      {/* Botón para ver/editar resultados */}
      <Box sx={{ padding: 2, borderBottom: '1px solid #ddd' }}>
        <Button
          variant="outlined"
          startIcon={<Edit />}
          onClick={handleViewEditResults}
        >
          Ver/Editar Resultados
        </Button>
      </Box>

      {/* Contenedor de Mensajes Desplazable */}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {chatStatus === 'loading' && <CircularProgress />}
        {chatStatus === 'failed' && (
          <Typography color="error">{chatError}</Typography>
        )}

        {chatStatus === 'succeeded' && (
          <List>
            {messages.slice().reverse().map((message) => (
              <ListItem
                key={message.id}
                alignItems="flex-start"
                sx={{
                  justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
                }}
              >
                <Paper
                  sx={{
                    padding: 1,
                    borderRadius: 2,
                    maxWidth: '75%',
                    backgroundColor: message.role === 'user' ? 'primary.main' : 'grey.200',
                    color: message.role === 'user' ? 'white' : 'black',
                    textAlign: 'left',
                  }}
                >
                  <ListItemText
                    primary={formatMessageContent(
                      message.content
                        .map((contentPiece) => contentPiece.text.value)
                        .join(' ')
                    )}
                  />
                </Paper>
              </ListItem>
            ))}
            {/* Referencia para el desplazamiento automático */}
            <div ref={messagesEndRef} />
          </List>
        )}
      </Box>

      {/* Barra de Entrada de Mensajes Fija */}
      <Box sx={{ padding: 2, borderTop: '1px solid #ddd', display: 'flex', alignItems: 'center' }}>
        <TextField
          placeholder="Escribe un mensaje"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          multiline
          fullWidth
          sx={{ marginRight: 1 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
          disabled={sendingStatus === 'loading'}
        >
          Enviar
        </Button>
      </Box>
    </Box>
  );
}

export default ChatPage;
