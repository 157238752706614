import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material';
import {
  CameraAlt,
  InsertDriveFile,
  ArrowBack,
} from '@mui/icons-material';
import Header from '../Header/Header';
import { useNavigate } from 'react-router-dom';
import { extractDataFromFile } from '../../services/openai';

function NewAnalysisPage() {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSelectFile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileType(file.type);
  };

  const handleContinue = async () => {
    if (!selectedFile) return;
    setLoading(true);
    try {
      const extractedData = await extractDataFromFile(selectedFile);
      localStorage.setItem('extractedData', JSON.stringify(extractedData));
      navigate('/summary');
    } catch (error) {
      console.error('Error al extraer datos:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/');
  };

  return (
    <>
      <Header
        title="Nuevo análisis. Tome una foto o seleccione un archivo."
        showBackButton
        onBack={handleBack}
      />
      <Box sx={{ padding: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CameraAlt />}
          fullWidth
          sx={{ marginBottom: 2 }}
          // Agrega la funcionalidad de tomar foto si es necesario
        >
          Tomar una foto
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<InsertDriveFile />}
          fullWidth
          component="label"
        >
          Seleccionar archivo
          <input type="file" hidden onChange={handleSelectFile} />
        </Button>

        {selectedFile && (
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body1">{selectedFile.name}</Typography>
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 4 }}
          onClick={handleContinue}
          disabled={!selectedFile || loading}
        >
          {loading ? 'Procesando...' : 'Continuar'}
        </Button>
      </Box>
    </>
  );
}

export default NewAnalysisPage;
