import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { AccountCircle, ArrowBack } from '@mui/icons-material'; // Importa el ícono correctamente
import { useSelector } from 'react-redux';

function Header({ title, showBackButton, onBack }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector((state) => state.user.data);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'primary.main', padding: 1 }}>
      <Toolbar>
        {showBackButton && (
          <IconButton edge="start" color="inherit" onClick={onBack}>
            <ArrowBack /> {/* Usa el componente de Material UI */}
          </IconButton>
        )}
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <IconButton edge="end" color="inherit" onClick={handleMenu}>
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Perfil</MenuItem>
          <MenuItem onClick={handleClose}>Configuración</MenuItem>
          <MenuItem onClick={handleClose}>Cerrar sesión</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
